body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background-color: #fcfdfd;
  color: #626262;
  overflow-x: hidden;
}
a {
  color: #21d796;
  text-decoration: none;
}
a:hover {
  color: #21d796;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #00ffcc;
}
img.brand_logo {
  width: 138px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f9f9fa;
  margin-top: 0 !important;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 !important;
  z-index: 999;
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.main_navbar a.nav-link {
  color: #9d9d9d;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.main_navbar a.nav-link.active,
.main_navbar a.nav-link:hover {
  color: #21d796;
  text-decoration: none;
}
.main_wrapper {
  min-height: 100vh;
}
.innerpages_wrapper {
  min-height: 100vh;
}
.main_navbar {
  border-radius: 60px;
  background: #f9f9fa;
  margin-top: 20px;
  padding: 24px 48px;
}
.inner_title_wrapper {
  margin: 35px 0;
}
.inner_title {
  color: #21d796;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_wrapper {
  border-radius: 60px;
  background: #fafafb;
  padding: 40px;
}
.dash_box {
  border-radius: 30px;
  background: #fefefe;
  box-shadow: 0px 15px 25px 0px #e9e9e9;
  padding: 30px;
  margin-bottom: 24px;
}
.dash_box_left_flex {
  display: flex;
  align-items: center;
}
.dash_box_left_flex > div {
  width: 100%;
}
.dash_box_left h3 {
  color: #212529;
  text-align: right;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_left h4 {
  color: #626262;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.affiliate_inpgrp {
  position: relative;
  margin: 14px 0;
}
.affiliate_inpgrp input {
  border-radius: 20px;
  background: #f5f5f7;
  border: 1px solid #f5f5f7;
  padding: 8px 12px;
  color: #212529;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.affiliate_list li a {
  background: #b4b4b4;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.affiliate_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}
.affiliate_list li a:hover {
  background: #21d796;
}
.affiliate_list li a svg {
  fill: #fff;
}
.copy_icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.txt_green {
  color: #21d796 !important;
}
.dash_box_right_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.dash_box_right_top h4 {
  color: #21d796;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_bottom h2 {
  color: #212529;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}
.dash_box_right_bottom h2 span {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.primary_btn {
  padding: 9px 18px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 700;
  color: #21d796;
  border: 1px solid #21d796;
  background: #fafafb;
  border-radius: 45px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
.primary_btn:hover {
  background-color: #23c483;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
}
.primary_btn_transform:hover {
  transform: translateY(-7px);
}
.button_grp {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom_progress {
  position: relative;
}
.progress_value {
  position: absolute;
  top: -7px;
  right: 0;
  color: #21d796;
  font-size: 14px;
  font-weight: 700;
}
.custom_progress .progress {
  border-radius: 10px;
  background: #ececef;
  height: 10px;
}
.custom_progress .progress,
.progress_note {
  width: 95%;
}
.progress_note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.progress_note label {
  color: #9d9d9d;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.progress_note label:nth-child(2) {
  color: #626262;
  font-weight: 700;
}
.custom_progress .progress-bar {
  background: #21d796;
  border-radius: 10px;
}
.dash_box_right h5 {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.dash_box_right_large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.dash_box_right_large_single {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
}
.dash_box_right_large_single h3 {
  color: #212529;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_large_single::after {
  content: "";
  background: #e0e0e0;
  width: 1px;
  height: 63px;
  position: absolute;
  right: -42px;
  top: 0;
}
.dash_box_right_large_single:last-child::after {
  display: none;
}
.dash_box_right_large_single h5 {
  margin-bottom: 18px;
}
.dash_box.dash_box_right.dash_box_right_large {
  padding-top: 50px;
  padding-bottom: 50px;
}
.dash_box.dash_box_right {
  padding-top: 36px;
  padding-bottom: 36px;
}
.inner_subtitle_wrap h3 {
  color: #21d796;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.inner_subtitle_wrap_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.primary_datatable .rdt_TableHeadRow .rdt_TableCol {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}
.primary_datatable .rdt_TableBody .rdt_TableRow,
.primary_datatable .rdt_TableHeadRow {
  border-bottom: 0;
}
.primary_datatable .rdt_Pagination {
  border-top: 0;
}
.primary_datatable .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: #f5f5f7;
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  text-transform: capitalize;
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}
.footer {
  background: #ededed;
  padding: 30px 0;
}
.footer_panel_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_panel_bottom p {
  color: #898989;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.footer_menu_links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px;
  margin: 0;
}
.footer_menu_links li {
  position: relative;
}
.footer_menu_links li::after {
  content: "";
  background: #898989;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 11px;
  right: -15px;
}
.footer_menu_links li:last-child:after {
  display: none;
}
.footer_menu_links li a {
  color: #898989;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  text-transform: capitalize;
}
p.footer_abt_content {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 60%;
}
.footer_panel_top h3 {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.42px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.footer_quick_links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer_quick_links li a {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.42px;
  text-transform: capitalize;
}
.footer_social_links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}
.footer_social_links li a {
  background: #626262;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #38dca1;
}
.footer_social_links li a svg {
  fill: #fff;
}
.footer_panel_top {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.auth_btn_grp {
  display: flex;
  gap: 15px;
}
.offcanvas-header .btn-close {
  box-shadow: none;
}
.toolbar_bottom {
  display: none;
}
.page_header {
  border-radius: 90px;
  background: #DAFFF2;
  padding: 90px 80px;
  margin-top: 50px;
  position: relative;
  min-height: 731px;
}
.page_header::after {
  content: "";
  background: url("../src/assets/images/banner_img.png") no-repeat top right;
  width: 800px;
  height: 731px;
  position: absolute;
  top: 0;
  right: 0;
}
.primary_btn_with_bg {
  background: #21D796;
  color: #FCFDFD;
  box-shadow: 0px 8px 20px 0px #B9FFE6;
}
.primary_btn_with_bg:hover {
  background: #FCFDFD;
  color: #21D796;
}
.page_header h1 {
  color: #212529;
  font-size: 48px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: -1.44px;
  margin-bottom: 30px;
}
.page_header h5 {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
  z-index: 10;
  position: relative;
}
.main_wrapper {
  min-height: 100vh;
}
.section {
  padding: 50px 0;
}
.countdown_single {
  background: url("../src/assets/images/contdown_shape.png") no-repeat;
  width: 362px;
  height: 362px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  padding: 20px 10px 20px 70px;
}
.countdown_panel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;  
}
.countdown_single svg {
  margin-left: 25px;
}
.countdown_single h3 {
  color: #212529;
  font-size: 42px; 
  font-weight: 800;
  line-height: 62px;
  letter-spacing: -1.26px;
  margin-bottom: 10px;
}
.countdown_single h4 {
  color: #626262;  
  font-size: 20px; 
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}
.countdown_section {
  padding: 0;
  margin-top: -170px;
  z-index: 10;
  position: relative;
}
.main_title {
  color: #212529;
  font-size: 48px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: -1.44px;
  margin-bottom: 30px;
}
.main_para {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}
.features_panel {
  border-radius: 60px;
  background: #DAFFF2;
  padding: 50px 80px;
}
.features_panel h3 {
  color: #21D796;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 30px 0;
}
.features_panel p.main_para {
  max-width: 85%;
}
.staking_works h2 {
  color: #38DCA1;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.6px;
}
.staking_works > div {
  position: relative;
  margin-left: 50px;
}
.staking_works > div::before {
  content: "";
  background: url("../src/assets/images/dot.png") no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  left: -50px;
}
.staking_works > div::after {
  content: "";
  background: #ACEFD3;
  height: 65px;
  width: 1px;
  position: absolute;
  top: 23px;
  left: -38px;
}
.staking_works > div:last-child::after {
  display: none;
}
.faq_accordian button {
  box-shadow: none !important;
}
.faq_accordian .accordion-item {
  border: none;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 0;
}
.faq_accordian .accordion-item:last-child {
  border-bottom: 0;
}
.faq_accordian .accordion-item button {
  background: transparent;
  color: #212529;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding: 30px 0;
}
.faq_accordian .accordion-button::after {
  background: url("../src/assets/images/faq_icon.png") no-repeat;
  width: 11px;
  height: 18px;
}
.faq_accordian .accordion-button:not(.collapsed)::after {
  transform: rotate(90deg);  
}
.faq_accordian .accordion-body {
  padding: 10px 0 15px;
}
.faq_accordian .accordion-body .main_para {
  margin-bottom: 0;
  max-width: 90%;
}
.auth_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0;
  position: relative;
}
.auth_wrapper .dash_box {
  background: #DAFFF2;
  position: relative;
  z-index: 1;
}
.auth_wrapper .dash_box::before {
  content: "";
  background: #FEDCD1;
  width: 400px;
  height: 400px;
  position: absolute;
  border-radius: 100%;
  filter: blur(150px);
  z-index: -1;
  right: 0;
}
.auth_form input.form-control {
  border-color: #21d796;
  border-radius: 30px;
  height: 56px;
  padding-left: 25px;
  box-shadow: none !important;
  color: #626262;
}
.auth_form input.form-control::placeholder {
  color: rgb(98 98 98 / 50%);
}
.auth_form .form-check-input:checked {
  background-color: #21d796;
  border-color: #21d796;
  box-shadow: none !important;
}
.auth_form .form-check-input:focus {
  box-shadow: none !important;
}
.auth_form .primary_btn_with_bg {
  padding: 12px 18px;
  font-size: 18px;
}
ul.nav_after_login {
  display: flex;
  align-items: center;
  gap: 20px;
}
.nav_after_login li {
  position: relative;
}
.nav_after_login li a.nav-link {
  color: #1E1E1E !important;  
  font-size: 14px;  
  font-weight: 500;
  line-height: normal;
}
.nav_after_login .dropdown svg {
  margin-right: 10px;
}
.notify_active {
  background: #02D287;
  border: 2px solid #fff;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav_after_login li a.dropdown-item {
  color: #1E1E1E;  
  font-size: 14px;  
  font-weight: 500;
  line-height: normal;
}
.nav_after_login li a.dropdown-item:focus, 
.nav_after_login li a.dropdown-item:hover {
  color: #fff;
  background-color: #21d796;
}
.level_top_row .dash_box_right {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  height: 100%;
}
.level_top_row .dash_box_left {
  height: 100%;
  padding: 10px 30px;
}
.level_top_row [class*="col-"] {
  margin-bottom: 24px;
}
.level_head,
.level_body_row {
  display: flex;
}
.level_head > div:nth-child(1),
.level_body_row > div:nth-child(1) {
  width: 25%;
  margin-right: 80px;
}
.level_head > div:nth-child(2),
.level_body_row > div:nth-child(2) {
  width: 40%;
}
.level_head > div:nth-child(3),
.level_body_row > div:nth-child(3) {
  width: 20%;
}
.level_head > div {
  color: #21D796;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.level_body_row > div {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.level_body_row {
  background: #f0f0f0;
  height: 56px;
  margin-bottom: 40px;
  align-items: center;
  border-radius: 30px;
}
.level_ribbon {  
  position: relative;
  background: url("../src/assets/images/level_default.png") left center no-repeat;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #21D796;
}
.level_ribbon.active {
  background: url("../src/assets/images/level_active.png") left center no-repeat;
  color: #fff;
}
.level_table {
  margin-top: 50px;
}
.level_head {
  padding-bottom: 30px;
}
.asset_dashbox_flex {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}
.asset_dashbox_flex_line {
  position: relative;
}
.asset_dashbox_flex_left, 
.asset_dashbox_flex_right {
  width: 50%;
}
.asset_dashbox_flex_line::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: #E0E0E0;
  position: absolute;
  right: 50%;
}
.asset_deposit_innertitle {
  color: #21D796;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.primary_warning_alert {
  border-radius: 29px;
  background: #F5F5F7;
  border-color: #F5F5F7;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.primary_warning_alert svg {
  min-width: 26px;
  height: 26px;
}
.primary_warning_alert h3 {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.primary_warning_alert p {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.deposit_notes_list {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  padding-left: 15px;
}
.deposit_notes_list li {
  margin-bottom: 15px;
}
.asset_deposit_grp {
  position: relative;
}
.asset_deposit_grp input {
  padding-right: 40px;
}
.primary_asset_inp {
  height: 45px;
  border-radius: 30px;
  background: #F5F5F7 !important;
  border-color: #F5F5F7 !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.asset_copy_icon {
  position: absolute;
  top: 15px;
  right: 18px;
  cursor: pointer;
}
.deposit_asset_details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deposit_asset_details > div label {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px; 
  text-transform: capitalize;
}
.deposit_asset_details > div label:nth-child(2) {
  color: #212529;
  font-weight: 600;
}
.inner_title_wrapper_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.assets_form label {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: block;
}
.withdrawal_type {
  color: #212529;  
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  right: 18px;
}
.deposit_asset_details > div.font_bold {
  margin-top: 30px;
}
.deposit_asset_details > div.font_bold label {
  color: #0B0E11;
  font-weight: 600;
}
label.max_label {
  color: #21D796;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 8px;
}
.transfer_modal .modal-dialog {
  max-width: 700px;
}
.primary_modal .modal-header h1 {
  color: #21D796;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}
.primary_modal .modal-header .btn-close {
  box-shadow: none !important;
}
.asset_modal_btn_grp {
  display: flex;
  gap: 24px;
}
.primary_asset_select {
  height: 45px;
  border-radius: 30px;
  background: #F5F5F7 url("../src/assets/images/select_dropdown.png") no-repeat;
  background-position: right 0.75rem center;
  background-size: 14px 9px !important;
  border-color: #F5F5F7 !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.asset_transfer_flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.trade_user_detail {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.trade_user_detail h3 {
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.96px;
  margin-bottom: 0;
}
.trade_user_detail img {
  box-shadow: 0 15px 25px 0 #E9E9E9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.copy_trade_row .dash_box {
  height: 100%;
}
.copy_trade_row [class*="col-"] {
  margin-bottom: 24px;
}
.cusform_form_switch .form-check-input {
  box-shadow: none !important;
  border-color: #d7d7d7;
}
.cusform_form_switch .form-check-input:checked {
  background-color: #21D796;
  border-color: #21D796;
}
.primary_tab {
  display: flex;
  gap: 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #EFEFEF;
}
.primary_tab li button.nav-link {
  border-radius: 50px;
  border: 1px solid #D9D9D9;
  color: #AFAFAF;
  background: transparent;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
}
.primary_tab li button.nav-link.active {
  border: 1px solid #D9D9D9;
  background: #D9D9D9;
  color: #0B0E11;
}
.notification_panel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #EFEFEF;
  padding: 30px 0;
  cursor: pointer;
}
.notification_panel_row:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.unread_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-top: 6px;
  background: #fd2626;
  position: absolute;
}
.read_dot{
  background: #fff;
}
.notification_row_content {
  margin-left: 15px;
}
.notification_row_content h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.notification_row_content p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
.notification_panel_row p.notify_date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--grey-07);
}
.notification_panel_row_empty > div {
  width: 100%;
  align-items: center;
}
.notification_panel_row > div {
  display: flex;
  align-items: flex-start;
  width: 65%;
}
.no_orders {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-direction: column;
  width: 100% !important;
}
.user_info {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info img {
  box-shadow: 0 15px 25px 0 #E9E9E9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.user_info_content h3 {
  color: #0B0E11;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}
.user_info_content h5 {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info_content h5 span {
  color: #626262;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.user_info_content h6 {
  color: #999999;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.user_info_content h6 span {
  cursor: pointer;
}
.center_line {
  margin: 0 5px;
  font-size: 10px;
}
.custom_file_upload  {
  position: relative;
  overflow: hidden;
  background: #21D796 !important;
  padding: 8px 30px;
  color: #fff !important;
}
.custom_file_upload input[type=file] {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}








/* Media Queries */
@media screen and (max-width:1399px) {
  .dash_wrapper {
    border-radius: 30px;
    padding: 20px;
  }
  .dash_box.dash_box_right {
    padding: 36px 20px;
  }
  .dash_box_right_large_single h3 {
    font-size: 18px;
  }
  .primary_btn.primary_btn_transform {
    font-size: 14px;
    padding: 8px 12px;
  }
  .dash_box_right_top h4 {
    font-size: 16px;
  }
  .dash_box_right_bottom h2 {
    font-size: 28px;
  }
  .page_header {
    padding: 80px 50px 50px;
    min-height: 640px;
  }
  .page_header::after {
    background-size: 88%;
  }
  .countdown_section {
    margin-top: -120px;
  }
  .level_head > div:nth-child(1), 
  .level_body_row > div:nth-child(1) {
    width: 32%;
  }
}
@media screen and (max-width:1199px) {
  .dash_box.dash_box_left {
    height: 100%;
  }
 .dashbox_left_inner_row [class*="col-"] {
    margin-bottom: 24px;
 }
 .page_header {
    text-align: center;
    min-height: 100%;
    padding: 80px 50px 80px;
 }
 .page_header::after {
    display: none;
 }
 .countdown_section {
    margin-top: 0;
    padding-top: 70px;
  }
  .countdown_panel {
    flex-wrap: wrap;
    justify-content: center;
  }
  .features_panel p.main_para {
    max-width: 100%;
  }
}
@media screen and (max-width:991px) {
  p.footer_abt_content {
    max-width: 90%;
  }
  .dash_box_right_large_single {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .dash_box_right_large_single h5 {
    margin-bottom: 10px;
  }
  .dash_box_right_large_single::after {
    height: 100%;
  }
  .navbar_left {
    display: none;
  }
  .navbar-toggler {
    filter: invert(1);
    box-shadow: none !important;
  }
  .auth_btn_grp {
    flex-direction: column;
  }
  .auth_btn_grp .primary_btn {
    display: block;
    text-align: center;
  }
  .footer {
    padding: 30px 0 80px;
  }
  .toolbar_bottom {
    display: block;
    position: fixed;
    bottom: 0;
    background: #21D796;
    width: 100%;    
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
    transition: transform 0.25s;
    z-index: 999;
  }
  .toolbar_bottom ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 15px;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .toolbar_bottom ul li svg {
    fill: #fff;
  }
  .toolbar_bottom ul li {
    text-align: center;
  }
  .toolbar_bottom ul li span {
    display: block;    
    color: #fff;
    font-size: 14px;
  }
  .toolbar_bottom .navbar-toggler {
    filter: unset;
  }
  .toolbar_bottom ul li a,
  .toolbar_bottom ul li button {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    padding: 12px;
  }
  .toolbar_bottom ul li a.active {
    background: #075e3f;
  }
  .level_head > div:nth-child(1), 
  .level_body_row > div:nth-child(1) {
    width: 40%;
    margin-right: 30px;
  }
  .asset_dashbox_flex {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .asset_dashbox_flex_left, 
  .asset_dashbox_flex_right {
    width: 100%;
  }
  .asset_dashbox_flex_line::after {
    display: none;
  }
  .notify_active {   
    right: unset;
    top: 6px;
    left: 25%;  
  }
}
@media screen and (max-width:767px) {
  .dash_box_right_large {
    flex-direction: column;
    align-items: flex-start;
  }
  .dash_box_right_large_single {
    width: 100%;
    flex-direction: row;
  }
  .dash_box_right_large_single::after {
    width: 100%;
    height: 1px;
    right: unset;
    top: unset;
    bottom: -15px;
  }
  .progress_note {
    flex-direction: column;
    gap: 10px;
  }
  .custom_progress .progress, 
  .progress_note {
    width: 92%;
  }
  .progress_note label {
    text-align: center;
  }
  .footer_panel_bottom {
    flex-direction: column;  
   text-align: center;
  }
  .footer_panel_bottom p {
    line-height: 24px;
  }
  .page_header {
    padding: 50px 30px 50px;
  }
  .page_header h1 {
    font-size: 36px;    
    line-height: 46px;
  }
  .countdown_single h3 {
    font-size: 32px;
    line-height: 42px;
  }
  .features_panel {
    padding: 30px 50px;
  }
  .main_title {    
    font-size: 36px;
    line-height: 42px;
  }
  .features_panel h3 {
    margin: 10px 0;
  }
  .auth_wrapper {
    padding: 50px 0;
  }
  .faq_accordian .accordion-item button {    
    font-size: 16px;
  }
  .level_head > div:nth-child(1), 
  .level_body_row > div:nth-child(1) {
    width: 180px;
    margin-right: 10px;
  }
  .level_head > div:nth-child(3), 
  .level_body_row > div:nth-child(3),
  .level_head > div:nth-child(2),
  .level_body_row > div:nth-child(2) {
    width: 150px;
    margin-right: 10px;
  }
  .deposit_asset_details > div {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .deposit_asset_details > div label {
    width: 100%;
    line-height: 24px;
  }
  .notification_panel_row {
    flex-direction: column;
  }
  .notification_panel_row p.notify_date {
    margin-left: 15px;
  }
  .level_ribbon,
  .level_ribbon.active {
    background-size: contain;
  }
  .notify_active {
    right: -25px;
    left: unset;
  }
}
@media screen and (max-width:575px) {
  .inner_subtitle_wrap_flex {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  } 
  .dash_box_right_large_single h3 {
    font-size: 16px;
  }
  .dash_box_right h5 {
    font-size: 14px;
  }
  .main_navbar {
    padding: 20px 20px;
  }
  .level_body_row > div,
  .level_head > div {
    font-size: 14px;
  }
  .custom_progress .progress, 
  .progress_note {
    width: 85%;
  }
  .asset_dashbox_flex {
    padding: 15px;
  }
  .assets_form label,
  label.max_label {
    font-size: 14px;
  }
  .deposit_asset_details > div label {
    font-size: 15px;
  }
  .asset_transfer_flex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .asset_transfer_flex img {
    transform: rotate(90deg);
  }
  .user_info {
    flex-direction: column;
    align-items: flex-start;
  }
}